<template>
  <div class="charts mb-3" style="height: 620px">
    <b-card class="p-3" style="height: 100%" no-body>
      <div v-if="data && data.length > 0">
        <ag-charts-vue
          :options="options">
        </ag-charts-vue>
      </div>
      <b-skeleton height="100%" v-else></b-skeleton>
    </b-card>
  </div>
</template>

<script>
import { AgChartsVue } from 'ag-charts-vue'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'

export default {
  name: 'projects-charts-pft',
  data() {
    return {
      options: null
    }
  },
  props: ['data'],
  components: {
    AgChartsVue
  },
  watch: {
    data() {
      this.setOptions()
    }
  },
  created() {
    this.setOptions()
  },
  methods: {
    setOptions() {
      this.options = {
        height: 600,
        data: [],
        series: []
      }
      if (this.data) {
        this.options = {
          height: 600,
          data: this.data,
          series: [
            {
              type: 'line',
              xKey: 'month',
              yKey: 'total_revenue',
              yName: 'Total Revenue',
              strokeWidth: 1,
              marker: {
                size: 10
              },
              tooltip: {
                renderer: function ({ datum, xKey, yKey }) {
                  return {
                    content: SimpleHelper.priceFormatter(datum[yKey]),
                    title: yKey.replaceAll('_', ' ')
                  }
                }
              }
            },
            {
              type: 'line',
              xKey: 'month',
              yKey: 'total_expenses',
              yName: 'Total Expenses',
              strokeWidth: 1,
              marker: {
                size: 10
              },
              tooltip: {
                renderer: function ({ datum, xKey, yKey }) {
                  return {
                    content: SimpleHelper.priceFormatter(datum[yKey]),
                    title: yKey.replaceAll('_', ' ')
                  }
                }
              }
            },
            {
              type: 'line',
              xKey: 'month',
              yKey: 'average_profitability',
              yName: 'Average Profitability',
              strokeWidth: 1,
              marker: {
                size: 10
              },
              visible: this.$store.getters.getPftChartSeriesVisible('average_profitability')
            },
            {
              type: 'line',
              xKey: 'month',
              yKey: 'active_projects',
              yName: 'Active Projects',
              strokeWidth: 1,
              marker: {
                size: 10
              },
              visible: this.$store.getters.getPftChartSeriesVisible('active_projects'),
              tooltip: {
                renderer: function ({ datum, xKey, yKey }) {
                  return {
                    content: `${SimpleHelper.monthYearStringToLabels(datum[xKey])}: ${datum[yKey]}`,
                    title: yKey.replaceAll('_', ' ')
                  }
                }
              }
            }
          ],
          tooltip: {
            class: 'custom-tooltip'
          },
          legend: {
            enabled: true,
            item: {
              label: {
                formatter: (params) => {
                  let total = this.data.reduce((acc, obj) => acc + obj[params.itemId], 0)
                  if (params.itemId === 'average_profitability') {
                    return String(params.value + `  (${(total / this.data.length).toFixed(0)}%)`)
                  } else if (params.itemId === 'active_projects') {
                    return params.value
                  } else {
                    return params.value + ` (${SimpleHelper.priceFormatter(total)})`
                  }
                }
              }
            },
            listeners: {
              legendItemClick: ({ seriesId, itemId, enabled }) => {
                this.$store.commit('setPftChartSeriesVisible', { key: itemId, value: enabled });
              }
            }
          },
          axes: [
            {
              type: 'category',
              position: 'bottom',
              label: {
                formatter: (params) => {
                  return SimpleHelper.monthYearStringToLabels(params.value)
                }
              }
            },
            {
              type: 'number',
              position: 'left',
              keys: ['total_revenue', 'total_expenses']
            },
            {
              type: 'number',
              position: 'right',
              keys: ['active_projects', 'average_profitability'],
              min: 0
            }
          ]
        }
      }
    }
  }
}
</script>
