<template>
  <date-range
    :selected-date-from="selectedDateFrom"
    :selected-date-to="selectedDateTo"
    :periodFrom="makePeriod('month', true)"
    :periodTo="makePeriod('month', false)"
    @changed-dates="changedDates"
  ></date-range>
</template>

<script>
import DateRange from '@/components/DateRange.vue'
import { makePeriod } from '@/mixins/period'
import { mapMutations } from 'vuex'

export default {
  name: 'header-date-picker',
  components: { DateRange },
  data() {
    return {
      projectFromDate: null,
      projectToDate: null
    }
  },
  props: {
    tableName: null
  },
  computed: {
    selectedDateFrom: {
      get() {
        return this.$store.state.managementTools.selectedDateFrom
      },
      set(value) {
        this.$store.commit('setSelectedDateFrom', value)
      }
    },
    selectedDateTo: {
      get() {
        return this.$store.state.managementTools.selectedDateTo
      },
      set(value) {
        this.$store.commit('setSelectedDateTo', value)
      }
    }
  },
  mounted() {
    this.setDefaultSelectedDates()
    this.getOrCreateSessionStorageData()
  },
  methods: {
    ...mapMutations([
      'setDefaultSelectedDates',
      'setSelectedDateFrom',
      'setSelectedDateTo'
    ]),
    makePeriod,
    changedDates(obj) {
      this.setSelectedDateFrom(obj.dateFrom)
      this.setSelectedDateTo(obj.dateTo)
      this.dataChanged()
      this.$emitter.emit('header_addons_changed', {})
    },
    dataChanged() {
      this.addProjectDataToSessionStorage()
      this.$emit('change')
    },
    getOrCreateSessionStorageData() {
      if (sessionStorage.getItem('projectSelectedData')) {
        let projectSelectedData = JSON.parse(
          sessionStorage.getItem('projectSelectedData')
        )

        this.selectedProjectType = {
          label: projectSelectedData.selectedProjectType?.label,
          code: projectSelectedData.selectedProjectType?.code
        }

        this.selectedSortBy = {
          label: projectSelectedData.selectedSortBy?.label,
          code: projectSelectedData.selectedSortBy?.code
        }

        this.selectedDateFrom = projectSelectedData.selectedDateFrom

        this.selectedDateTo = projectSelectedData.selectedDateTo

        this.selectedProjectsSearch = projectSelectedData.selectedProjectsSearch
      } else {
        this.addProjectDataToSessionStorage()
      }
    },
    addProjectDataToSessionStorage() {
      sessionStorage.setItem(
        'projectSelectedData',
        JSON.stringify({
          selectedProjectType: {
            label: this.selectedProjectType?.label,
            code: this.selectedProjectType?.code
          },

          selectedSortBy: {
            label: this.selectedSortBy?.label,
            code: this.selectedSortBy?.code
          },

          selectedDateFrom: this.selectedDateFrom,

          selectedDateTo: this.selectedDateTo,

          selectedProjectsSearch: this.selectedProjectsSearch
        })
      )
    }
  }
}
</script>
