<template>
  <div class="projects-charts">
    <button id="charts-show-button" v-on:click="chartsPft" :class="['button-outline-blue', 'item', showChartsPft ? 'router-link-active' : '', 'chart-button']"><b-icon icon="bar-chart"></b-icon><span>PFT</span></button>
    <button id="charts-show-button" v-on:click="chartsExp" :class="['button-outline-blue', 'item', showChartsExp ? 'router-link-active' : '', 'chart-button']"><b-icon icon="bar-chart"></b-icon><span>EXP</span></button>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'header-projects-charts',
  data() {
    return {}
  },
  computed: {
    showChartsPft: {
      get() {
        return this.getShowChartsPft()
      },
      set(value) {
        if (value) {
          this.setShowChartsExp(false)
        }
        this.setShowChartsPft(value)
      }
    },
    showChartsExp: {
      get() {
        return this.getShowChartsExp()
      },
      set(value) {
        if (value) {
          this.setShowChartsPft(false)
        }
        this.setShowChartsExp(value)
      }
    }
  },
  beforeDestroy() {
    this.$emitter.off('selected_rows_event')
  },
  methods: {
    ...mapMutations([
      'setShowChartsPft',
      'setShowChartsExp',
    ]),
    ...mapGetters([
      'getShowChartsPft',
      'getShowChartsExp',
    ]),
    chartsPft() {
      this.showChartsPft = !this.showChartsPft
    },
    chartsExp() {
      this.showChartsExp = !this.showChartsExp
    }
  }
}
</script>

<style lang="scss" scoped>
.projects-charts {
  display: flex;
  gap: 10px;
}
.chart-button {
  line-height: normal;
  span {
    margin-left: 5px;
  }
}
</style>
