<template>
  <div class="charts mb-3" style="height: 670px">
    <b-card class="p-3" style="height: 100%" no-body>
      <div v-if="data && data.map && data.map.length > 0">
        <button @click="toggleMode" class="button-outline-blue ml-2">
          {{ mode === 'dollars' ? 'Switch to Hours' : 'Switch to Dollars' }}
        </button>
        <ag-charts-vue
          :options="options">
        </ag-charts-vue>
      </div>
      <b-skeleton height="100%" v-else></b-skeleton>
    </b-card>
  </div>
</template>

<script>
import { AgChartsVue } from 'ag-charts-vue'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'

export default {
  name: 'projects-charts-exp',
  data() {
    return {
      options: null,
      areSeriesVisible: true,
      mode: 'dollars'
    }
  },
  props: ['data'],
  components: {
    AgChartsVue
  },
  watch: {
    data() {
      this.setOptions()
    }
  },
  created() {
    this.setOptions()
  },
  methods: {
    setOptions() {
      this.options = {
        height: 600,
        data: [],
        series: []
      }
      const series = []
      if (this.data && this.data.activityTags) {
        for (const activityTag of this.data.activityTags) {
          series.push({
            type: 'bar',
            xKey: 'month',
            stacked: true,
            yKey: activityTag.name,
            yName: activityTag.label,
            fill: activityTag.color,
            stroke: activityTag.color,
            visible: this.$store.getters.getExpChartSeriesVisible(activityTag.name),
            tooltip: {
              renderer: ({ datum, xKey, yKey }) => {
                return {
                  content: this.mode === 'dollars' ? SimpleHelper.priceFormatter(datum[yKey]) : datum[yKey] + 'h',
                  title: yKey.replaceAll('_', ' ')
                }
              }
            }
          })
        }
        this.options = {
          height: 600,
          data: this.mode === 'dollars' ? this.data.map : this.data.mapHours,
          series: series,
          legend: {
            enabled: true,
            item: {
              label: {
                formatter: (params) => {
                  if (this.mode === 'dollars') {
                    let total = this.data.map.reduce((acc, obj) => {
                      let value = Number(obj[params.itemId])
                      return isNaN(value) ? acc : acc + value
                    }, 0)
                    return params.value + ` (${SimpleHelper.priceFormatter(total)})`
                  } else {
                    let total = this.data.mapHours.reduce((acc, obj) => {
                      let value = Number(obj[params.itemId])
                      return isNaN(value) ? acc : acc + value
                    }, 0)
                    return params.value + ` (${total.toFixed(1)}h)`
                  }
                }
              }
            },
            listeners: {
              legendItemClick: ({ seriesId, itemId, enabled }) => {
                this.$store.commit('setExpChartSeriesVisible', { key: itemId, value: enabled });
              }
            }
          },
          axes: [
            {
              type: 'category',
              position: 'bottom',
              label: {
                formatter: (params) => {
                  return SimpleHelper.monthYearStringToLabels(params.value)
                }
              }
            },
            {
              type: 'number',
              position: 'left'
            }
          ]
        }
      }
    },
    toggleMode() {
      this.mode = this.mode === 'dollars' ? 'hours' : 'dollars'
      this.setOptions()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
